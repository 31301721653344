/* eslint-disable */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AccountDataQuery = { __typename: 'Query', account?: { __typename: 'Account', id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null } | null };

export type SessionDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SessionDataQuery = { __typename: 'Query', session?: { __typename: 'Session', id: string, token: any, expires: any, impersonatedBy?: { __typename: 'Admin', id: string, firstName: string, lastName: string } | null } | null };


export const AccountDataDocument = gql`
    query accountData {
  account {
    id
    firstName
    middleName
    lastName
  }
}
    `;

/**
 * __useAccountDataQuery__
 *
 * To run a query within a React component, call `useAccountDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountDataQuery(baseOptions?: Apollo.QueryHookOptions<AccountDataQuery, AccountDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountDataQuery, AccountDataQueryVariables>(AccountDataDocument, options);
      }
export function useAccountDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountDataQuery, AccountDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountDataQuery, AccountDataQueryVariables>(AccountDataDocument, options);
        }
export type AccountDataQueryHookResult = ReturnType<typeof useAccountDataQuery>;
export type AccountDataLazyQueryHookResult = ReturnType<typeof useAccountDataLazyQuery>;
export type AccountDataQueryResult = Apollo.QueryResult<AccountDataQuery, AccountDataQueryVariables>;
export const SessionDataDocument = gql`
    query sessionData {
  session {
    id
    token
    expires
    impersonatedBy {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useSessionDataQuery__
 *
 * To run a query within a React component, call `useSessionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionDataQuery(baseOptions?: Apollo.QueryHookOptions<SessionDataQuery, SessionDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionDataQuery, SessionDataQueryVariables>(SessionDataDocument, options);
      }
export function useSessionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionDataQuery, SessionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionDataQuery, SessionDataQueryVariables>(SessionDataDocument, options);
        }
export type SessionDataQueryHookResult = ReturnType<typeof useSessionDataQuery>;
export type SessionDataLazyQueryHookResult = ReturnType<typeof useSessionDataLazyQuery>;
export type SessionDataQueryResult = Apollo.QueryResult<SessionDataQuery, SessionDataQueryVariables>;