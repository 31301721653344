"use client";

import React, { useState, useEffect } from "react";
import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import { useLazyQuery } from "@apollo/client";
import LoadingPlaceholder from "../LoadingPlaceholder";

import { queries } from "../../../api";

import { useSession } from "../../../contexts/session";

import { useFeatureFlagsQuery } from "../../utilities/featureFlag";

import useRefreshCacheOnExternalSignIn from "./useRefreshCacheOnExternalSignIn";
import useCreateSessionFromToken, {
	useLongLivedTokenParam,
} from "./useCreateSessionFromToken";

const initializeAppStateMachine = createMachine({
	id: "app-initialization",
	initial: "idle",
	states: {
		idle: {
			always: [
				{
					target: "longLivedTokenUnverified",
					cond: "hasLongLivedToken",
				},
				{
					target: "sessionUnverified",
					cond: "hasValidSession",
				},
				{
					target: "sessionInvalid",
				},
			],
		},
		initialized: {},
		longLivedTokenUnverified: {
			entry: "createSessionFromToken",
			on: {
				VALIDATE_LONG_LIVED_TOKEN: {
					target: "initialized",
				},
				INVALIDATE_LONG_LIVED_TOKEN: [
					{
						target: "sessionUnverified",
						cond: "hasValidSession",
					},
					{
						target: "sessionInvalid",
					},
				],
			},
		},
		sessionInvalid: {
			entry: "removeSession",
			always: {
				target: "initialized",
			},
		},
		sessionUnverified: {
			entry: "fetchInitializeApp",
			on: {
				VALIDATE_SESSION: {
					target: "initialized",
				},
				INVALIDATE_SESSION: {
					target: "sessionInvalid",
				},
			},
		},
	},
});

const InitializeApp = ({ children }: { children: React.ReactNode }) => {
	const [isClient, setIsClient] = useState(false);
	// LLT query param will be set if the user signed in from an external link
	const [longLivedToken, setLongLivedTokenParam] = useLongLivedTokenParam();

	useEffect(() => {
		setIsClient(true);
	}, []);

	const { hasAuthenticatedSession, hasSessionExpired, removeSession } =
		useSession();

	const [createSessionFromToken] = useCreateSessionFromToken(longLivedToken, {
		onCompleted: () => {
			setLongLivedTokenParam(null);
			// eslint-disable-next-line no-use-before-define
			send("VALIDATE_LONG_LIVED_TOKEN");
		},
		onError: () => {
			setLongLivedTokenParam(null);
			// eslint-disable-next-line no-use-before-define
			send("INVALIDATE_LONG_LIVED_TOKEN");
		},
	});

	// @ts-ignore
	const [fetchInitializeApp] = useLazyQuery(queries.INITIALIZE_APP, {
		/**
		 * For the initial query, we ensure consistency with the server.
		 * Subsequent queries may use cached values.
		 */
		fetchPolicy: "network-only",
		// eslint-disable-next-line no-use-before-define
		onCompleted: () => send("VALIDATE_SESSION"),
		// eslint-disable-next-line no-use-before-define
		onError: () => send("INVALIDATE_SESSION"),
	});

	// @ts-ignore
	const [current, send] = useMachine(initializeAppStateMachine, {
		guards: {
			hasValidSession: () => hasAuthenticatedSession && !hasSessionExpired,
			hasLongLivedToken: () => Boolean(longLivedToken),
		},
		actions: {
			createSessionFromToken,
			removeSession,
			fetchInitializeApp,
		},
	});

	/*
	 * Fetch features regardless of the state of the user's session (existence
	 * and/or validity)
	 */
	const { refetch: fetchFeatures } = useFeatureFlagsQuery();

	useRefreshCacheOnExternalSignIn({
		shouldRefresh: current.matches("initialized"),
		refreshCache() {
			fetchInitializeApp();
			fetchFeatures();
		},
	});

	if (!isClient) {
		return null;
	}

	if (
		current.matches("idle") ||
		current.matches("sessionUnverified") ||
		current.matches("longLivedTokenUnverified")
	) {
		return <LoadingPlaceholder />;
	}

	return <>{children}</>;
};

export default InitializeApp;
