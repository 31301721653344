import React from "react";
import { formatFullName } from "../../../utilities/stringFormat";
import {
	useAccountDataQuery,
	useSessionDataQuery,
} from "./operations.generated";

const ImpersonationBanner = () => {
	const { data: accountData = null } = useAccountDataQuery({
		fetchPolicy: "cache-and-network",
	});

	const { data: sessionData = null } = useSessionDataQuery({
		fetchPolicy: "cache-and-network",
	});

	const { account = null } = accountData || {};
	const { session = null } = sessionData || {};
	const { impersonatedBy = null } = session || {};

	if (!impersonatedBy) {
		return null;
	}

	return (
		<div className="z-banner-important relative">
			<div className="bg-warning flex justify-center px-5 py-4">
				<p className="text-body-default w-full text-center text-white">
					{formatFullName(account)} is being impersonated by{" "}
					{formatFullName(impersonatedBy)}
				</p>
			</div>
		</div>
	);
};

export default ImpersonationBanner;
