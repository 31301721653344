import { useState } from "react";
import ResizeObserver from "resize-observer-polyfill";

import { useIsomorphicLayoutEffect } from "usehooks-ts";

export default function useDimensions(ref) {
	// Keep track of measurements
	const [dimensions, setDimensions] = useState({
		x: 0,
		y: 0,
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		width: 0,
		height: 0,
	});

	useIsomorphicLayoutEffect(() => {
		const node = ref.current;
		if (!node) {
			return undefined;
		}

		// Define measure function
		const measure = (innerNode) => {
			const rect = innerNode.getBoundingClientRect();
			setDimensions({
				x: rect.left,
				y: rect.top,
				left: rect.left,
				top: rect.top,
				right: rect.right,
				bottom: rect.bottom,
				width: rect.width,
				height: rect.height,
			});
		};

		// Set initial measurements
		measure(node);

		// Observe resizing of element
		const resizeObserver = new ResizeObserver(() => {
			measure(node);
		});

		resizeObserver.observe(node);

		// Cleanup
		return () => {
			resizeObserver.disconnect();
		};
	}, [ref]);

	return dimensions;
}
