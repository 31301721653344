"use client";

import * as R from "ramda";
import { useQuery } from "@apollo/client";

import { useEnvironmentVariables } from "contexts/environmentVariables";
import { queries } from "../../../api";

import {
	type PossibleFeatureFlags,
	type FeatureFlag,
	featureFlags,
} from "./feature-flags";

export type FlagRecord = Record<PossibleFeatureFlags, boolean>;

const isRunningCypress = typeof window !== "undefined" && window.Cypress;

export const environmentVariableToBoolean = (
	variable: "1" | "0" | "false" | "true" | undefined,
) => {
	switch (variable) {
		case "1":
		case "true":
			return true;
		case "0":
		case "false":
		default:
			return false;
	}
};

// @ts-expect-error
const parseFeaturesFromQuery = R.compose(
	R.mergeAll,
	R.map(({ name, isEnabled }) => ({ [name]: isEnabled })),
);

// Set a feature flag per environment
// @ts-expect-error
export const environmentFeatures: FlagRecord = R.compose(
	R.fromPairs,
	R.map(([key, flag]) => [key, environmentVariableToBoolean(flag)]),
	R.reject(([, value]) => R.isNil(value)),
	R.map(([key, flag]: [string, FeatureFlag]) => [key, flag.env]),
	R.toPairs,
)({ ...featureFlags });

// Grabs default features
// @ts-expect-error
export const defaultFeatures: FlagRecord = R.compose(
	R.fromPairs,
	R.map(([key, flag]) => [key, flag]),
	R.reject(([, value]) => R.isNil(value)),
	R.map(([key, flag]: [string, FeatureFlag]) => [key, flag.defaultValue]),
	R.toPairs,
)({ ...featureFlags });

export const useFeatureFlagsQuery = () => useQuery(queries.FEATURES);

const useFeatureFlag = (flag: PossibleFeatureFlags) => {
	const { runtimeEnvironment } = useEnvironmentVariables();

	const { data = {} } = useFeatureFlagsQuery();
	const { features = [] } = data;
	const queryFeatures: any = parseFeaturesFromQuery(features);

	const isStorybook = runtimeEnvironment === "storybook";

	if (!flag) {
		throw new Error(
			"useFeatureFlag checks against a single feature. No feature flag was supplied.",
		);
	}

	if (flag in defaultFeatures) {
		return defaultFeatures[flag];
	}

	if (flag in environmentFeatures && !isRunningCypress && !isStorybook) {
		return environmentFeatures[flag];
	}

	if (flag in queryFeatures) {
		return queryFeatures[flag];
	}

	return false;
};

export default useFeatureFlag;
