import { useFeatureFlagsQuery } from "./featureFlag";

type Props = {
	children: React.ReactNode;
};

const WaitForFeatureFlags = ({ children }: Props) => {
	const { loading: isLoading } = useFeatureFlagsQuery();

	if (isLoading) {
		return null;
	}

	return children;
};

export default WaitForFeatureFlags;
