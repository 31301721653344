import { useMutation } from "@apollo/client";

import { mutations } from "api";

import { useSession } from "contexts/session";
import useQueryParams from "components/utilities/useQueryParams";
import { toast } from "components/utilities/toast";

export function useLongLivedTokenParam() {
	const [{ llt }, setParams] = useQueryParams();
	return [llt, (token) => setParams({ llt: token })];
}

export default function useCreateSessionFromToken(
	token,
	{ onCompleted, onError },
) {
	const { createSession } = useSession();

	return useMutation(mutations.CREATE_SESSION_FROM_TOKEN, {
		variables: { token },
		onCompleted: ({ createSessionFromToken }) => {
			const { session = {}, account = {} } = createSessionFromToken;

			createSession(session);

			const message = account.email
				? `You are now logged in as ${account.email}.`
				: "You are now logged in.";

			toast.success(message);

			onCompleted?.();
		},
		onError: () => {
			onError?.();
		},
	});
}
