"use client";

import React, { useRef, useEffect } from "react";
import useDimensions from "components/utilities/useDimensions";
import { BannerPortal } from "components/organisms/banners";
import ImpersonationBanner from "./ImpersonationBanner";

const isBrowser = typeof window !== "undefined";

function AppBanners() {
	const bannerRef = useRef<HTMLDivElement>();
	const { height } = useDimensions(bannerRef);

	useEffect(() => {
		if (isBrowser) {
			document.body.style.setProperty("--banner-height", `${height}px`);
		}
	}, [height]);
	return (
		// @ts-ignore
		<div ref={bannerRef} className="z-header sticky top-0">
			<ImpersonationBanner />
			<BannerPortal />
		</div>
	);
}

export default AppBanners;
