import React from "react";
import clsx from "clsx";

import { Spinner, SpinnerVariant } from "@jasperlabs/jux-next";

type Props = {
	className?: string;
	spinnerVariant?: SpinnerVariant;
	variant?: "page" | "placeholder";
};

const LoadingPlaceholder = ({
	className,
	spinnerVariant = SpinnerVariant.Primary,
	variant = "page",
	...props
}: Props) => (
	<div
		className={clsx(
			"flex w-full items-center justify-center",
			variant === "page" && ["h-screen"],
			variant === "placeholder" && ["h-full"],
			className,
		)}
		{...props}
	>
		<div>
			<Spinner variant={spinnerVariant} />
		</div>
	</div>
);

export default LoadingPlaceholder;
