import { useQuery } from "@apollo/client";

import { queries } from "../../api";

import { useSession } from "../../contexts/session";

/**
 * The combination of session (from window.localStorage) & the account queried
 * from the API. Specifically for identifying users for analytics, logging etc.
 */
const useAuthenticatedUser = () => {
	const { hasAuthenticatedSession } = useSession();
	const { data = {} } = useQuery(queries.ME);
	const { me = null } = data;

	// An "empty" account in the cache exists, but its id doesn't have a value
	if (hasAuthenticatedSession && me?.id) {
		return me;
	}

	return null;
};

export default useAuthenticatedUser;
