"use client";

import { useEffect, useState } from "react";
import { usePathname, useSearchParams, useRouter } from "next/navigation";

const RouteActiveInvestingEntity = () => {
	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const investingEntityId = searchParams.get("active_investing_entity");
	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	if (!isClient) {
		return null;
	}

	if (investingEntityId) {
		const searchParamsCopy = new URLSearchParams(searchParams);
		searchParamsCopy.delete("active_investing_entity");
		const queryString = searchParamsCopy.toString();

		const returnUrl = `${pathname}${queryString ? `?${queryString}` : ""}`;
		router.push(
			`/set-active-investing-entity/${investingEntityId}?returnUrl=${encodeURIComponent(
				returnUrl,
			)}`,
		);
	}

	return null;
};

export default RouteActiveInvestingEntity;
