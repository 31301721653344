"use client";

import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

const PORTAL_ID = "banner-portal";
const getElement = () => document.getElementById(PORTAL_ID);

export const RenderIntoBanner = ({ children }) => {
	const [container, setContainer] = useState(getElement);

	/**
	 * If the element ever changes from state, update state.
	 * If children ever change, update state.
	 * useEffect runs once on mount too - ensures we have at least 2 passes to find the portal.
	 */
	useEffect(() => {
		const element = getElement();
		if (container !== element) {
			setContainer(element);
		}
	}, [container, children]);

	if (container) {
		return createPortal(children, container);
	}

	return null;
};

RenderIntoBanner.defaultProps = { children: null };
RenderIntoBanner.propTypes = { children: PropTypes.node };

const BannerPortal = () => <div id={PORTAL_ID} />;

export default BannerPortal;
