import { useCallback, useMemo } from "react";
import * as R from "ramda";
import { useSearchParams, useRouter } from "next/navigation";

const emptyString = () => "";
const decode = R.unless(
	R.isNil,
	R.tryCatch(decodeURIComponent, () => null),
);
const encode = R.compose(
	R.tryCatch(encodeURIComponent, emptyString),
	R.when(R.isNil, emptyString),
);

function objectFromParams(params) {
	return Array.from(params).reduce(
		(values, [key, value]) => ({ ...values, [key]: decode(value) }),
		{},
	);
}

const useQueryParams = (defaultParams) => {
	const searchParams = useSearchParams();
	const router = useRouter();

	const params = useMemo(
		() => ({
			...defaultParams,
			...objectFromParams(searchParams),
		}),
		[defaultParams, searchParams],
	);

	const setParams = useCallback(
		(newParams) => {
			const currentParams = new URLSearchParams(searchParams.toString());
			R.forEachObjIndexed((value, key) =>
				value
					? currentParams.set(key, encode(value))
					: currentParams.delete(key),
			)(newParams);
			router.replace(`?${currentParams.toString()}`, { scroll: false });
		},
		[searchParams, router],
	);

	return [params, setParams];
};

export default useQueryParams;
