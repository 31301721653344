"use client";

import React, { createContext, useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

const routerContext = createContext();

export function useRouterState() {
	const context = useContext(routerContext);

	if (!context) {
		throw new Error(
			"useRouterState should always be used within a RouterStateProvider",
		);
	}

	return context;
}

export const RouterStateProvider = ({ children, ...rest }) => {
	const [routerState, setRouterState] = useState({});

	const value = useMemo(
		() => ({
			routerState,
			setRouterState,
		}),
		[routerState],
	);

	return (
		<routerContext.Provider value={value} {...rest}>
			{children}
		</routerContext.Provider>
	);
};

RouterStateProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
