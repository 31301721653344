import { useApolloClient } from "@apollo/client";

import { queries } from "../../../api";

import { useSession } from "../../../contexts/session";
import useValueListener from "../../utilities/useValueListener";

const useHasAccountInCache = () => {
	const client = useApolloClient();
	const data = client.readQuery({ query: queries.ME });

	return Boolean(data?.me?.id);
};

const useRefreshCacheOnExternalSignIn = ({ refreshCache, shouldRefresh }) => {
	const { hasAuthenticatedSession } = useSession();
	const hasAccountInCache = useHasAccountInCache();

	useValueListener({
		value: shouldRefresh && hasAuthenticatedSession && !hasAccountInCache,
		onChange({ previous, value }) {
			if (!previous && value) {
				refreshCache();
			}
		},
	});
};

export default useRefreshCacheOnExternalSignIn;
