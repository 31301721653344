"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { useConstantRefCallback } from "@jasperlabs/hooks";

import { useSessionRemovedListener } from "contexts/session";
import { analytics } from "lib/analytics";

import useAuthenticatedUser from "../useAuthenticatedUser";

const useUnmountCleanup = (cleanup: () => void) => {
	const cleanupRef = useConstantRefCallback(cleanup);

	useEffect(() => cleanupRef, [cleanupRef]);
};

export function Analytics() {
	/**
	 * If the user is authenticated, identify them
	 */
	const user = useAuthenticatedUser();
	const pathname = usePathname();

	const { id: userId } = user ?? {};
	useEffect(() => {
		if (userId) {
			analytics.identify(userId);
		}
	}, [userId]);

	/**
	 * Track page views
	 */
	useEffect(() => {
		const id = setTimeout(() => analytics.page(), 1000);

		return () => {
			clearTimeout(id);
		};
	}, [pathname]);

	/**
	 * When the user ends their session, reset
	 */
	useSessionRemovedListener(() => analytics.reset());

	/**
	 * When this component unmounts for any reason, reset
	 */
	useUnmountCleanup(() => analytics.reset());

	return null;
}
