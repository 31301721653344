import React, { Fragment } from "react";

import useFeatureFlag from "./featureFlag";
import { type PossibleFeatureFlags } from "./feature-flags";

type Props = {
	feature: PossibleFeatureFlags;
	fallback?: React.ReactNode;
	children: React.ReactNode;
};

const BehindFeature = ({ feature, fallback, children }: Props) => {
	const isFeatureEnabled = useFeatureFlag(feature);

	if (isFeatureEnabled) {
		return <>{children}</>;
	}

	return <Fragment>{fallback}</Fragment>;
};

export default BehindFeature;
